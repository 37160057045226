import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Acceleration = m/s²',
      paragraph: 'We bring great ideas, stellar leaders and aspirational teams to build exceptional businesses. Our companies specialize in going from concept to business in rapid time.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>

              <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                  Our Approach
                  </h3>
                  <p className="m-0">
                  We ideate, develop, launch and scale businesses in the area of education and sustainability. Our bias to action and results gives our ventures an outstanding advantage to succeed. The team at M by S2 has decades of experience in – education, sustainability and technology. A great idea is not the same as a great company. Our diligence process in the venture building has been mastered over two decades of building and scaling companies globally
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-03',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../../assets/images/features-split-image-01.svg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                  The process
                  </h3>
                  <p className="m-0">
                  - <b>Opportunity Analysis:</b> It all starts with an idea. An idea rooted in deep insights of the sectors we operate in. Insights of inefficiencies that are opportune to be addressed in a meaningful way. <br/><br/>- <b>Strategy Formulation:</b> Our strategy formulation is deliberate. Unique in its set of integrated set of choices exploiting all available resources within our ecosystem.<br/><br/> - <b>Execution:</b> Execution is everything. Structure, legal, compliance, hiring, compensation and benefits, infrastructure, industry and customer connects and access to sectoral expertise is just the beginning of the M by S2 execution framework.<br/><br/> - <b>Scaling:</b> Scale shouldn’t take time. Once an idea has shown it can work, then going large is where the advantage lies. <br/><br/>- <b>Governance:</b> Governance has to be a hygiene factor. Its non negotiable and part of the value system. <br/><br/>This approach has resulted in a portfolio of companies across education and sustainability.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-04',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../../assets/images/features-split-image-02.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item reveal-scale-up">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                  Our portfolio
                  </h3>
                  <p className="m-0">
                  Here are our stories, the great, the bad and the ones in between. Across the education and sustainability sectors.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image is-3d illustration-element-05',
                    imageFill && 'split-item-image-fill'
                  )}>
                  <Image
                    src={require('./../../assets/images/features-split-image-03.svg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;