import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull';
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Secondary extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Buttons - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection02Header = {
      title: 'Input forms - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection03Header = {
      title: 'Modal - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection04Header = {
      title: 'FAQ - Lorem ipsum is placeholder text commonly used.'
    }

    return (
      <React.Fragment>
        <Team />

        <GenericSection topDivider>
          <div className="container-xs">
            <h4>Current areas of interest</h4>
            <p>
            Here are some of the fields we are looking to venture into.
            </p>
            <ul>
              <li><b>Gaming Platform:</b> Our gaming platform is relooking the areas of business education. Why is it content and not experience – a question our platform answers with a solution.</li>
              <li><b>AI and ML Platform:</b> We are currently building and AI and ML platform to focus on the impact of these technologies on the spaces that we operate in.</li>
              <li><b>Electric Mobility:</b> The future of transportation is changing, and this opens a universe of opportunities in smart, responsible travel and transportation. We are building a platform to look into various market opportunities in this space ranging from – storage and charging infrastructure, passenger and personal transportation and commercial transportation.</li>
            </ul>
          </div>
          <br>
          </br>
          <div className="container-xs">
            <h4>Other Adventures, External Investments and Joint Ventures</h4>
            <p>
            Here are some of the fields we are looking to venture into.
            </p>
            <ul>
              <li><b> SecondRoof**:</b>Was set up to make international real estate investments accessible to people. With high disposable income and lower interest costs, global markets offer more options for real estate for investment or vacation. An execution journey that taught us a wealth of lessons on what to look for in start-ups. An idea we will keep on hold to revisit in the near future.</li>
              <li><b>Germin8*:</b>Germin8 is a Social Media Intelligence company focused on helping you understand and act in real time on the gazillions of conversations by your stakeholders.</li>
              <li><b>IDE Infotech**:</b>Was the largest AIDC, bar code company in India. Having a dominant position in the oil and gas and retail industry. IDE Infotech was responsible to conceptualize and execute the largest oil and gas, inventory management system tracking and tracing over 500 M USD of inventory across the country.</li>
            </ul>
            <p>
            *Angel Investment.<br/>
            **Earlier Investments
            </p>
          </div>
        </GenericSection>
        <Cta topDivider bottomDivider split />
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default Secondary;