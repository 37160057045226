import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Modal, Button } from 'react-rainbow-components';

const textStyles = {
    textAlign: 'center',
    fontSize: 15,
    padding: '0 16px',
};

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Team extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        isOpen: false,
        story: ""
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
}

handleOnClick(a) {
    let story = ""
    if(a===1)
    {
      story = "I started dreaming of my first venture in middle school. Finally got to start one the day after college. This was when it wasn’t a cool thing and people said it can’t be done.\nIt’s the unfinished problems that still move me. My first meaningful problem was to deliver quality education at scale over fragile infrastructure. This went on to become one of the largest portals delivering education in over 155 countries and streaming over 5 million hours of content.\nIf we must do something, let it be meaningful. If it must be done, be different and let’s do it our way.\nProblems that matter are hard. And it’s those problems that need to be solved. One of the problems that need to be solved, we don’t have a Planet B (yet). This got me involved in our sustainability platform, looking to solve old problems in new ways.\nI don’t have the answers but work with our teams in framing better questions.\nAlways be ready to learn. The newest things I’m learning – to fly a plane and to be a better entrepreneur at Harvard Business School (yes, I’m showing off)."
    }
    else{
      story = "Funnily enough I started life saying I don’t want to work. This was obviously during the euphoric days of college when problems are magically solved and money was a parent’s problem. Since then, I have completed over three and a half decades of working. I like to believe I am an educationist. I like to believe that education can impact lives for the better. And learning need not only be in institutions. Learning for learnings sake is as important as simply education.\nPassion matters to me. If you aren’t passionate about what you are doing, then I can guarantee you aren’t running a business, you are working at a job. Every morning, I have to get up with a feeling that I am already late on a number of things on my to-do list.\nAll my life, I have approached people at work with a simple truth. Be closely distant and distantly close. Be there for when they need me but don’t get involved so much that I lose myself in their problem. I like to work with people who approach work like I do. Professionalism matters, integrity has to be a hygiene factor and Ethics have to be unquestionable. I hold these true to myself and expect it from everyone I work with. My meaning of work-life balance is not about counting the hours. It’s about not missing family events and not missing deadlines at work. One can’t be compromised for the other."
    }
    return this.setState({ isOpen: true,story:story });
}

handleOnClose() {
    return this.setState({ isOpen: false });
}

  render() {
    const { isOpen } = this.state;
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Acceleration = m/s²',
      paragraph: 'We bring great ideas, stellar leaders and aspirational teams to build exceptional businesses. Our companies specialize in going from concept to business in rapid time.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <Modal
                    id="modal-2"
                    isOpen={isOpen}
                    onRequestClose={this.handleOnClose}
                    title="My Story"
                    footer={
                        <div className="rainbow-flex rainbow-justify_end">
                        </div>
                    }
                >
                    <p style={textStyles}>
                        {this.state.story}
                    </p>
                </Modal>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-left" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-scale-up">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24 illustration-element-06">
                      <Image
                        src={require('./../../assets/images/anand.jpeg')}
                        alt="Team member 01"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                    Anand Nagarajan
                    </h5>
                    <div className="team-item-role text-xs fw-500 mb-8">
                    Co-Foudner
                    </div>
                    <p className="m-0 text-sm">
                    18 years as an entrepreneur in education, IT, energy and agriculture.
                    </p>
                    <Button
                      label="My Story"
                      onClick={() => this.handleOnClick(1)}
                      variant="brand"
                      className="rainbow-m-around_medium"
                  />
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-scale-up" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24 illustration-element-07">
                      <Image
                        src={require('./../../assets/images/hema.jpeg')}
                        alt="Team member 02"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                    Hemalatha Srinivasan
                    </h5>
                    <div className="team-item-role text-xs fw-500 mb-8">
                      Co-Founder
                    </div>
                    <p className="m-0 text-sm">
                    36 years of overall experience. 18 of them moving effortlessly between managing companies at scale and establishing startups. Currently cofounder of M/S squared mentoring startups to entrepreneurial success.
                    </p>
                    <Button
                      label="My Story"
                      onClick={this.handleOnClick}
                      variant="brand"
                      className="rainbow-m-around_medium"
                  />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;